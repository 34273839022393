import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { alertError, ResponseError } from "./error";
import { successful } from "./successMsg";

export type User = {
  uniqname: string;
  fullname: string;
  umid: string;
  canvasId: string;
  section: string;
  lateDaysLeft: string;
  extraSubmitsPerDay: string;
};

export const isInstructor = (user: User | null | undefined) =>
  user !== null && user !== undefined && user.section === "STAFF";

const queryUser = async () => {
  const response = await fetch("/api/v1/account/me");
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  const user = await response.json();
  return user as User;
};

const loginUser = async (searchParam: string) => {
  const response = await fetch("/api/v1/auth/token" + searchParam);
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  return await queryUser();
};

const logoutUser = async () => {
  const response = await fetch("/api/v1/auth/logout", { method: "POST" });
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  return response;
};

const queryUserList = async () => {
  const response = await fetch("/api/v1/account/");
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  const userList = await response.json();
  return userList as Array<User>;
};

export const queryUserKeys = ["user", "account"];
export const useUserQuery = () =>
  useQuery({
    queryKey: queryUserKeys,
    queryFn: queryUser,
    staleTime: Infinity,
  });

export const queryUserListKeys = ["user", "userList"];
export const useUserListQuery = () =>
  useQuery({
    queryKey: queryUserListKeys,
    queryFn: queryUserList,
    staleTime: 60 * 1000,
  });

export const useUserLogin = (searchParam: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => loginUser(searchParam),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["user"] }),
    onError: (err: Error) => alertError(toast, err),
  });
};

export const useUserLogout = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => logoutUser(),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["user"] }),
    onError: (err: Error) => alertError(toast, err),
  });
};

export const useUserAdd = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (uniqname: string) => {
      const res = await fetch("/api/v1/account/" + uniqname, {
        method: "post",
      });
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "userList"],
      }),

    onError: (err: Error) => alertError(toast, err),
    onSuccess: (data, uniqname, context) =>
      successful(toast, {
        title: "Student Added",
        msg: uniqname + " added to Autograder.",
      }),
  });
};

export const useUserDelete = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: async (uniqname: string) => {
      const res = await fetch("/api/v1/account/" + uniqname, {
        method: "delete",
      });
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return await res.json();
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "userList"],
      }),
    onError: (err: Error) => alertError(toast, err),
    onSuccess: (data, uniqname, context) =>
      successful(toast, {
        title: "Student Removed",
        msg: uniqname + " removed from Autograder.",
      }),
  });
};

export function UserLevel({
  notLogIn,
  student,
  staff,
}: {
  notLogIn: ReactElement;
  student: ReactElement;
  staff: ReactElement;
}) {
  const { data: user } = useUserQuery();
  if (user === null) {
    return notLogIn;
  }
  if (isInstructor(user)) {
    return staff;
  }
  return student;
}
