import { alertError } from "@/lib/error";
import {
  Text,
  CircularProgress,
  Center,
  useToast,
  Progress,
  Button,
} from "@chakra-ui/react";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";

export const QuestionMarkElement = () => {
  return (
    <Center>
      <Text size="70px" paddingY="100px" fontSize="70px" color="grey">
        ?
      </Text>
    </Center>
  );
};

export function LoadingFrame({
  query,
  PendingElement,
  ErrorElement,
  children,
}: {
  query: UseQueryResult;
  PendingElement: JSX.Element;
  ErrorElement: JSX.Element;
  children: (data: any) => JSX.Element;
}) {
  const toast = useToast();
  if (query.isFetching) {
    return PendingElement;
  }
  if (query.error) {
    alertError(toast, query.error);
    return ErrorElement;
  }
  return children(query.data);
}

export function MutationButton({
  mutation,
  mutationProps,
  isDisabled = false,
  onFinish = () => {},
  children,
  ...props
}: {
  mutation: UseMutationResult<any, any, any, any>;
  mutationProps: any;
  isDisabled?: boolean;
  onFinish?: () => void;
  children: React.ReactNode;
  [key: string]: any;
}) {
  const onClick = () => {
    mutation.mutate(mutationProps);
  };
  if (mutation.isPending) {
    return (
      <Button isLoading {...props} isDisabled={isDisabled} onClick={onClick}>
        {children}
      </Button>
    );
  }
  // When Mutation finishes
  if (mutation.isSuccess) {
    onFinish();
  }
  return (
    <Button {...props} isDisabled={isDisabled} onClick={onClick}>
      {children}
    </Button>
  );
}

export const CircularPendingElement = () => {
  return (
    <Center>
      <CircularProgress isIndeterminate size="70px" paddingY="100px" />
    </Center>
  );
};

export const LinearPendingElement = () => {
  return (
    <Center>
      <Progress size="xs" isIndeterminate />
    </Center>
  );
};

export const EmptyPendingElement = () => {
  return <></>;
};
