import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserLogin } from "@/lib/user";
import { CircularPendingElement } from "@/components/LoadingFrame";

export default function LoginSuccess() {
  const login = useUserLogin(window.location.search);
  const navigate = useNavigate();
  useEffect(() => {
    login.mutate();
  }, []);
  if (login.isSuccess) {
    navigate("/");
  }
  return <CircularPendingElement />;
}
