import SlimContainer from "@/components/SlimContainer";
import { useLinkStyle } from "@/lib/linkStyle";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Code,
  Text,
  VStack,
  Link,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { ReactNode } from "react";

type QuestionAnswer = {
  question: ReactNode;
  answer: ReactNode;
};

type QuestionSet = {
  title: string;
  questions: Array<QuestionAnswer>;
};

function ExternalLink() {
  const linkStyle = useLinkStyle();
  return (
    <Link
      style={linkStyle}
      href="https://docs.google.com/forms/d/e/1FAIpQLSe8BxRNnZKgRI4o-V7eG5F6LY_GhhWjMyJW8yKcP4XKVm2JrQ/viewform?usp=sf_link"
      isExternal
    >
      Google Form
    </Link>
  );
}

function AccordionQuestionAnswer({
  questionAnswer,
}: {
  questionAnswer: QuestionAnswer;
}) {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text fontSize="large" fontWeight="600">
            {questionAnswer.question}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>{questionAnswer.answer}</AccordionPanel>
    </AccordionItem>
  );
}
function AccordionQuestionSet({ questionSet }: { questionSet: QuestionSet }) {
  return (
    <Box width="100%">
      <Text fontSize="xx-large">{questionSet.title}</Text>
      <Accordion allowMultiple>
        <>
          {questionSet.questions.map((question: QuestionAnswer) => (
            <AccordionQuestionAnswer questionAnswer={question} />
          ))}
        </>
      </Accordion>
    </Box>
  );
}

const quickTips: QuestionSet = {
  title: "Quick Tips",
  questions: [
    {
      question: "How do I read the AG output?",
      answer: (
        <>
          <Text>
            This is a common question, and here's the quick instruction guide
            for interpreting the AG output (visible once you click on the
            timestamp for any of your submissions from your submissions page).
            From top to bottom, here's the AG output in a nutshell:
          </Text>
          <Text>
            <Text fontWeight={600}>Static analysis section:</Text> tells you
            stuff we noticed before compiling your code. If you're getting a
            very low score, look here. If not, you're free to ignore this
            section, as we don't grade on style in this course. On CAEN, run
            make static with your configured makefile to see this exact output!
          </Text>
          <Text>
            <Text fontWeight={600}>Build warnings/errors section:</Text> tells
            you the output generated by the make command. If you're getting a
            zero, it's probably because of something in this section. Remember
            to update all of the TODOs in your Makefile before submitting!
          </Text>
          <Text>
            <Text fontWeight={600}>Scoring student executable section:</Text>{" "}
            tells you how your code performed on each public test case on the
            AG. If you're failing a test case, check the relevant test case info
            here, as the output is likely to help you figure out why!
          </Text>
          <Text>
            <Text fontWeight={600}>Scoring student test files section:</Text>{" "}
            tells you information about the test cases you submitted to try to
            catch bugs on the AG. Remember to check here every time you write
            new test files! If your test cases expose bugs in your own code,
            we'll tell you exactly how!
          </Text>
          <Text>
            <Text fontWeight={600}>Testing for memory leaks section:</Text>{" "}
            tells you if your program leaked memory during execution. If you
            didn't get full points here, running valgrind on your debug
            executable is the best way to find out why! Total points earned
            section: tells you how many points overall you got, for the public
            test cases, your test files, and memory leaks. Good for a summary of
            your submission.
          </Text>
        </>
      ),
    },
    {
      question: "The autograder gives you hints? Where are they?",
      answer: (
        <Text>
          In your full run output (click the timestamp on your submission),
          search (Ctrl+F on Windows/Linux, Cmd+F on Mac) for the word hint. If
          your code has a common bug in it that we know one of our test cases
          exposes, we usually let you know with a hint, below the relevant test
          case output. Be sure to check for these every time!
        </Text>
      ),
    },
    {
      question: "Does the hint mean that this is definitely my problem?",
      answer: (
        <Text>
          Not necessarily. There's no "intelligence" built into the hint system,
          just a trigger like "If they go over time, tell them this" or "If they
          have the wrong output, say this". We've identified the most common way
          that a particular problem occurs, and created a hint that captured
          that information.
        </Text>
      ),
    },
  ],
};

const misc: QuestionSet = {
  title: "Miscellaneous",
  questions: [
    {
      question: "My memory leak checking failed; what happened?",
      answer: (
        <Text>
          We run leak-checking on several test cases, and there are several
          reasons that you could see the message "Testing for memory leaks:
          Could not run leak-checking. Your program exited with error." Your
          program ended with a result code other than 0, such as from{" "}
          <Code>exit(1)</Code> or throwing an exception. Your program took too
          long to finish running valgrind (over 2.5 minutes). If your program is
          taking a large amount of time, valgrind can take so much time to
          complete that it is eventually terminated. Valgrind found errors with
          your program more serious than a memory leak, such as uninitialized
          variables, etc. Run valgrind on your own and clean up these errors.
        </Text>
      ),
    },
    {
      question: "How do late days work?",
      answer: (
        <Text>
          When you use a late day, you basically grant yourself an extension on
          the project. Everyone gets 2 late days per semester, use them wisely!
          Don't put off Project 1, use your late days on the first project, then
          on Project 2 tell us that your computer died and now you need more
          late days. Suppose today is Thursday and your project was due on
          Tuesday, and that you did not use a late day to submit on Wednesday.
          If you want to submit today, you need to use 2 late days to submit,
          because you are submitting two days late, and had the ability to work
          on your code for 2 days. If this wasn't the policy, you could submit
          Project 1 two months late using 1 late day, then submit Project 2 a
          month late using your other late day, gaining you a total of 90 extra
          days to work on the code for those two projects.
        </Text>
      ),
    },
    {
      question: "My computer crashed and I lost all my code, what do I do?",
      answer: (
        <>
          <Text>
            This happens to one or two students every semester. You should be
            doing at least a few of the following things to prevent this:
          </Text>
          <Text>
            <Text fontWeight={600}>Backing up your computer.</Text> You keep
            backups, right? It's cheap and will save you in more than just EECS
            281. Buy a cheap external hard drive and get in the habit of
            starting a backup every night using some convenient backup software.
            There are also paid services that will back up your files to the
            cloud automatically. If you are not the sort who wants to keep a
            backup schedule, this may be appealing for you.
          </Text>
          <Text>
            <Text fontWeight={600}>
              Submitting to the autograder regularly.
            </Text>{" "}
            Even if your code doesn't compile, it's saved on the autograder for
            future download. Plus, you can submit test files and start getting
            points early, and staff can look at what you have so far if you have
            any questions.
          </Text>
          <Text>
            <Text fontWeight={600}>
              Storing your code on a free version control website.
            </Text>{" "}
            You can get free private repositories at gitlab.eecs.umich.edu,
            among other places. This also lets you revert to any previous
            version of your code, so that you can try bugfixes and performance
            enhancements without worrying about screwing up your project. If you
            have questions about Git, feel free to ask on Piazza or in office
            hours.
          </Text>
          <Text>
            If you accidentally delete a file on CAEN, you can also go into the{" "}
            <Code>~/.oldfiles</Code> directory and see if it's still there. (But
            it's better to use a version control system like Git.)
          </Text>
        </>
      ),
    },
    {
      question: "How many adorable sugar gliders do you employ on staff?",
      answer: (
        <Text>
          We don't release that information. Come join staff and you'll find
          out!
        </Text>
      ),
    },
  ],
};

const finalGrading: QuestionSet = {
  title: "Final Grading",
  questions: [
    {
      question:
        "Do you use my last submission or my best submission for final grading?",
      answer: (
        <Text>
          We now use your best submission, based upon the best score received
          before the deadline. In the case of a tie on score, the most recent
          submission is used.
        </Text>
      ),
    },
    {
      question: "Can I have you use a different submission for final grading?",
      answer: (
        <Text>
          If you would prefer us to use your last submission INSTEAD OF your
          best submission, use this <ExternalLink />.
        </Text>
      ),
    },
    {
      question:
        "I have one submission left before the project is due, and I want to try something, but if it doesn't work I don't want to lose points.",
      answer: (
        <Text>
          You can submit your code as usual, and if it doesn't work out, we will
          automatically use your best score for final grading.
        </Text>
      ),
    },
    {
      question: "How does final grading work?",
      answer: (
        <Text>
          We run your code against the published test cases and a small number
          of other "hidden" cases. If you fail the hidden test cases, it is not
          uncommon for your score to decrease by 5% or so. It is also possible
          for your score to go up because of hidden test cases. When we run
          final grading, only one student is run at a time, reducing the
          variation in run times that you might observe when 4-5 other students
          are running at the same time. After final grading is done running, we
          check on any student that had a score of 100 and lost it, and any
          student losing more than 1 point, looking for discrepancies, such as a
          test case that used to be green going blue. A score is not actually
          final until it is on Canvas.
        </Text>
      ),
    },
  ],
};

const regularGrading: QuestionSet = {
  title: "Regular Grading",
  questions: [
    {
      question:
        "My code ran fine earlier today, but I submitted the exact same thing and now it's running substantially more slowly.",
      answer: (
        <Text>
          There will always be a small amount of variability in the runtime of
          your test cases. Additionally, when many people are being graded
          simultaneously, it tends to slow down people's programs. It is not
          uncommon for scores to decrease by as much as 10%. We run final
          grading submissions one-by-one, so you can rest assured that you won't
          get penalized for your final grade because other people are in the
          queue.
        </Text>
      ),
    },
    {
      question:
        "Are the memory budgets for the peak memory usage of my program or the total memory usage?",
      answer: <Text>Peak memory usage.</Text>,
    },
    {
      question: "How does leak-checking work?",
      answer: (
        <Text>
          We run your code under valgrind for a few of the test cases and check
          for memory leaks. If any of those have memory leaks, then you wil lose
          points.
        </Text>
      ),
    },
    {
      question:
        'What does "Could not run leak-checking. Your program exited with error." mean?',
      answer: (
        <Text>
          This could mean a variety of things. Your program might have exited
          with status 1 (because of a crash, or because you did an{" "}
          <Code>exit(1)</Code> when you should have let <Code>main()</Code>{" "}
          perform a <Code>return 0</Code>, for example). It may also have taken
          too much time to run under valgrind, in which case you will need to
          optimize your code. If you are crashing for some test cases, then you
          may not get valgrind points. If you are passing all test cases but not
          getting the memory points, then you need to use valgrind to debug your
          code. If you're going over on memory, you need to optimize your code.
        </Text>
      ),
    },
    {
      question: "What is a malicious request?",
      answer: (
        <>
          <Text>
            This means that you accidentally submitted a tarball file that can
            potentially penetrate the autograder. This is a security risk, and
            we have to treat it as such. If you see this message, please ensure
            that you are not submitting anything that could potentially harm the
            autograder. Here is an enumeration of possible cause of this error:
            <OrderedList>
              <ListItem>
                <Text fontWeight="bold">Symbolic Links: </Text>
                Your submission tarball file contains a symbolic link.
              </ListItem>
              <ListItem>
                <Text fontWeight="bold">Special Characters: </Text>
                Your submission tarball includes a file with a name that
                contains special characters, such as <Code>..</Code>,{" "}
                <Code>/</Code>, <Code>\</Code>, or <Code>$</Code>.
              </ListItem>
            </OrderedList>
          </Text>
        </>
      ),
    },
  ],
};

const website: QuestionSet = {
  title: "Website",
  questions: [
    {
      question: "Does SIG always mean that I got a segfault?",
      answer: (
        <Text>
          No. There are many reasons that your program may exit with a signal.
          Check your detailed output for the specific reason. Do not assume that
          "SIG" means segfault. A few other reasons that your program may have a
          SIG: it threw an exception, ran out of memory, or printed too much
          output. If your detailed feedback (click on the timestamp) says
          something like <Code>SIGXFSZ</Code>, Google it.
        </Text>
      ),
    },
    {
      question: "What do the colors in the test matrix mean?",
      answer: (
        <Text>
          Green means that you passed the test case and met the runtime and
          memory budgets. Red means that you failed the test case (because of a
          wrong answer or program crash). Blue means that you passed the test
          case, but failed to meet runtime and/or memory budgets.
        </Text>
      ),
    },
    {
      question: "I'm losing points even though I passed all test cases.",
      answer: (
        <Text>
          Read the autograder output carefully: it may say that you used a
          prohibited library.
        </Text>
      ),
    },
    {
      question:
        "The autograder says that I included a prohibited library, but I didn't.",
      answer: (
        <Text>
          The <Code>forward_list</Code> library includes <Code>memory</Code>,
          which is prohibited. You don't need a <Code>forward_list</Code>{" "}
          anyway; just use a regular <Code>list</Code>. Additionally, the{" "}
          <Code>locale</Code> and <Code>iomanip</Code> libraries include the
          prohibited
          <Code>unique_ptr</Code> library. You should never need{" "}
          <Code>locale</Code>. Don't use <Code>iomanip</Code>
          unless the project requires it, such as for <Code>
            setw()
          </Code> or <Code>setprecision()</Code>.
        </Text>
      ),
    },
  ],
};
export default function FAQ() {
  return (
    <SlimContainer>
      <VStack spacing={3}>
        <AccordionQuestionSet questionSet={quickTips} />
        <AccordionQuestionSet questionSet={finalGrading} />
        <AccordionQuestionSet questionSet={regularGrading} />
        <AccordionQuestionSet questionSet={website} />
        <AccordionQuestionSet questionSet={misc} />
      </VStack>
    </SlimContainer>
  );
}
