import { CreateToastFnReturn } from "@chakra-ui/react";

export function successful(
  toast: CreateToastFnReturn,
  info: { title: string; msg: string | null },
) {
  const id = "success" + info.title + info.msg + Date();
  if (!toast.isActive(id)) {
    toast({
      id: id,
      title: info.title,
      description: info.msg,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }
}
