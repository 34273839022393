import { useQuery } from "@tanstack/react-query";
import { ResponseError } from "./error";

export type Health = {
  healthy: boolean;
  profile: "prod" | "test" | "dev";
};

export const useHealthQuery = () => {
  return useQuery({
    queryKey: ["health"],
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const url = "/api/v1/healthy/";
      const res = await fetch(url);
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return (await res.json()) as Health;
    },
  });
};
