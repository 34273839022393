import { Assignment, useAssignmentEdit } from "@/lib/assignment";
import {
  Box,
  StackDivider,
  Text,
  VStack,
  Spacer,
  Button,
  HStack,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  ModalOverlay,
  useDisclosure,
  FormControl,
  Checkbox,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import SlimContainer from "@/components/SlimContainer";
import {
  CircularPendingElement,
  LoadingFrame,
  QuestionMarkElement,
} from "@/components/LoadingFrame";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAssignmentQuery } from "@/lib/assignment";
import { LinkWithPretend } from "@/lib/pretend";
import { formatDate } from "@/lib/datetime";

interface EditFormValues {
  isVisible: boolean;
}

function AssignmentEditModal({ assignment }: { assignment: Assignment }) {
  const defaultEditFormValues: EditFormValues = {
    isVisible: assignment.isVisible === "1",
  };

  const { handleSubmit, register } = useForm<EditFormValues>({
    defaultValues: defaultEditFormValues,
  });
  const assignmentEdit = useAssignmentEdit();
  const submit: SubmitHandler<EditFormValues> = async (data) => {
    var newAssignment = assignment;
    newAssignment.isVisible = data.isVisible ? "1" : "0";
    assignmentEdit.mutate({
      assignmentId: assignment.projectId,
      assignment: newAssignment,
    });
  };
  return (
    <ModalContent as="form" onSubmit={handleSubmit(submit)}>
      <ModalHeader>
        Edit {assignment.projectId}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <FormControl>
          <Checkbox
            defaultChecked={assignment.isVisible === "1"}
            id="isVisible"
            {...register("isVisible")}
          >
            Visible
          </Checkbox>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <HStack spacing={2}>
          <Button>Benchmarks</Button>
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={assignmentEdit.isPending}
          >
            Confirm
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
}

function AssignmentItem({ assignment }: { assignment: Assignment }) {
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  return (
    <Box key={assignment.projectId} width="100%">
      <Stack direction={["column", "row", "row"]} width="100%">
        <Box>
          <Text fontSize="x-large">{assignment.projectName}</Text>
          <Text fontSize="medium">{assignment.projectId}</Text>
          <Text fontSize="medium">
            <Text as="span" fontWeight="bold">
              Open:{" "}
            </Text>
            {assignment.openingTime
              ? formatDate(assignment.openingTime)
              : "null"}
          </Text>
          <Text fontSize="medium">
            <Text as="span" fontWeight="bold">
              Due:{" "}
            </Text>
            {assignment.closingTime
              ? formatDate(assignment.closingTime)
              : "null"}
          </Text>
          <Text fontSize="medium">
            <Text as="span" fontWeight="bold">
              Accepted Util:{" "}
            </Text>
            {assignment.hardClosingTime
              ? formatDate(assignment.hardClosingTime)
              : "null"}
          </Text>
        </Box>
        <Spacer />
        <Button
          as={LinkWithPretend}
          to={"/graphs/" + assignment.projectId}
          className="my-auto"
        >
          Graphs
        </Button>
        <Button
          as={LinkWithPretend}
          to={"/extensionadminlist/" + assignment.projectId}
          className="my-auto"
        >
          Extensions
        </Button>
        <Button onClick={onEditOpen} className="my-auto" colorScheme="teal">
          Edit
        </Button>
      </Stack>
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <AssignmentEditModal assignment={assignment} />
      </Modal>
    </Box>
  );
}

export default function AssignmentAdminList() {
  const view = (
    <LoadingFrame
      query={useAssignmentQuery()}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(assignments: Array<Assignment>) => (
        <VStack divider={<StackDivider />} width="100%">
          {assignments.map((assignment: Assignment) => (
            <AssignmentItem
              assignment={assignment}
              key={assignment.projectId}
            />
          ))}
        </VStack>
      )}
    </LoadingFrame>
  );
  return <SlimContainer>{view}</SlimContainer>;
}
