import SlimContainer from "@/components/SlimContainer";
import {
  AssignmentSelector,
  SemesterSelector,
  SubmissionSelector,
  UniqnameSelector,
} from "@/components/Archive";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
  useSteps,
  VStack,
  Button,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { downloadFile } from "@/lib/archive";

function ArchiveStepper() {
  const [semester, setSemester] = useState<string | undefined>(undefined);
  const [assignment, setAssignment] = useState<string | undefined>(undefined);
  const [uniqname, setUniqname] = useState<string | undefined>(undefined);
  const [submission, setSubmission] = useState<string | undefined>(undefined);
  const result = [semester, assignment, uniqname, submission];
  const setResult = [setSemester, setAssignment, setUniqname, setSubmission];
  const steps = [
    { title: "Semester", description: semester },
    { title: "Assignment", description: assignment },
    { title: "Uniqname", description: uniqname },
    { title: "Submission", description: submission },
  ];
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <VStack width="100%">
      <Stepper size="lg" index={activeStep} hideBelow="md">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box>
              <StepTitle>{step.title}</StepTitle>
              {step.description !== undefined &&
                step.description !== "" &&
                activeStep > index && (
                  <StepDescription>{step.description}</StepDescription>
                )}
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Stepper size="lg" index={activeStep} hideFrom="md">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Text fontWeight="bold" hideFrom="md">
        {steps[activeStep].title}
      </Text>
      <Box minW="300" width="50%" className="mx-auto">
        {activeStep === 0 ? (
          <SemesterSelector semester={semester} setSemester={setSemester} />
        ) : (
          <></>
        )}
        {activeStep === 1 ? (
          <AssignmentSelector
            semester={semester || ""}
            assignment={assignment}
            setAssignment={setAssignment}
          />
        ) : (
          <></>
        )}
        {activeStep === 2 ? (
          <UniqnameSelector
            semester={semester || ""}
            assignment={assignment || ""}
            uniqname={uniqname}
            setUniqname={setUniqname}
          />
        ) : (
          <></>
        )}
        {activeStep === 3 ? (
          <SubmissionSelector
            semester={semester || ""}
            assignment={assignment || ""}
            uniqname={uniqname || ""}
            submission={submission}
            setSubmission={setSubmission}
          />
        ) : (
          <></>
        )}
      </Box>
      <Stack direction={["column", "row"]}>
        <Button
          onClick={(_) => {
            setResult[activeStep](undefined);
            goToPrevious();
          }}
          isDisabled={activeStep === 0}
        >
          Previous
        </Button>
        <Button
          isDisabled={
            result.some(
              (param: string | undefined) =>
                param === undefined || param === "",
            ) || activeStep !== steps.length - 1
          }
          onClick={(_) =>
            downloadFile(
              semester || "",
              assignment || "",
              uniqname || "",
              submission || "",
            )
          }
          colorScheme="teal"
        >
          Download
        </Button>
        <Button
          onClick={goToNext}
          isDisabled={
            result[activeStep] === undefined ||
            result[activeStep] === "" ||
            activeStep === steps.length - 1
          }
        >
          Next
        </Button>
      </Stack>
    </VStack>
  );
}

export default function Archive() {
  return (
    <SlimContainer>
      <ArchiveStepper />
    </SlimContainer>
  );
}
