import { useQuery } from "@tanstack/react-query";
import { ResponseError } from "./error";

export type QueueObject = {
  uniqname: string;
  projectId: string;
  timestamp: string;
};

async function query(queueName: string) {
  const res = await fetch("/api/v1/queue/" + queueName);
  if (res.status !== 200) {
    const errorData = await res.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  const ret = Object.assign(new Array<QueueObject>(), await res.json());
  return ret;
}

export const useQueueQuery = (queueName: string) => {
  return useQuery({
    queryFn: async () => query(queueName),
    queryKey: ["user", "queue", queueName],
    refetchOnWindowFocus: false,
  });
};
