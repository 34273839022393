import { ActionMeta, Select, SingleValue } from "chakra-react-select";
import React from "react";

class Option {
  value: string = "";
  label: string = "";
}

function optionHelper(uniqname: string) {
  return { value: uniqname, label: uniqname };
}

export function StudentSelect({
  uniqnames,
  uniqname,
  setUniqname,
}: {
  uniqnames: Array<string>;
  uniqname: string | undefined;
  setUniqname: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const options = uniqnames.map(optionHelper);
  const handleChange = (option: SingleValue<Option>, _: ActionMeta<Option>) => {
    if (option) setUniqname(option.value);
    else setUniqname(undefined);
  };
  return <Select options={options} isSearchable onChange={handleChange} />;
}
