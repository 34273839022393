import { useQuery } from "@tanstack/react-query";
import { ResponseError } from "./error";
import { usePretend, pretendUrl } from "./pretend";

export const useFileQuery = (
  assignmentId: string,
  timestamp: string,
  fileName: string,
  destServer: string,
) => {
  const { pretend } = usePretend();
  return useQuery({
    queryKey: ["user", "file", assignmentId, timestamp, fileName, pretend],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const url =
        "/api/v1/file/submission/" +
        assignmentId +
        "/" +
        timestamp +
        "/" +
        fileName;
      const res = await fetch(pretendUrl(url, pretend), {
        headers: { destServer: destServer },
      });
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return res.text();
    },
  });
};

export const useTestQuery = (assignmentId: string, testId: string) => {
  return useQuery({
    queryKey: ["user", "test", assignmentId, testId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const url = "/api/v1/file/test/" + assignmentId + "/" + testId;
      const res = await fetch(url);
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return res.text();
    },
  });
};

export const useTestCorrectOutputQuery = (
  assignmentId: string,
  testId: string,
) => {
  return useQuery({
    queryKey: ["user", "testCorrectOutput", assignmentId, testId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const url = "/api/v1/file/correctOutput/" + assignmentId + "/" + testId;
      const res = await fetch(url);
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return res.text();
    },
  });
};

export const useFileListQuery = (
  assignmentId: string,
  timestamp: string,
  destServer: string,
) => {
  const { pretend } = usePretend();
  return useQuery({
    queryKey: ["user", "filelist", assignmentId, timestamp, pretend],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const url =
        "/api/v1/file/submission/" + assignmentId + "/" + timestamp + "/";
      const res = await fetch(pretendUrl(url, pretend), {
        headers: { destServer: destServer },
      });
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return Object.assign(new Array<string>(), await res.json());
    },
  });
};
