import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "@/pages/Home";
import LoginSuccess from "./pages/LoginSuccess";
import Assignment from "./pages/Assignment";
import { NotFound } from "@/pages/NotFound";
import AssignmentAdminList from "@/pages/AssignmentAdminList";
import ExtensionAdminList from "@/pages/ExtensionAdminList";
import reportWebVitals from "./reportWebVitals";
import FileList from "./pages/FileList";
import File from "./pages/File";
import Archive from "./pages/Archive";
import Layout from "./Layout";
import ServerAdminList from "@/pages/ServerAdmin";
import { AssignmentLayout } from "./Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import About from "./pages/About";
import { Queue } from "./pages/Queue";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import FAQ from "./pages/FAQ";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const Graphs = lazy(() => import("./pages/Graphs"));
const StudentAdmin = lazy(() => import("./pages/StudentAdmin"));
const TestCase = lazy(() => import("./pages/TestCase"));
// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

// Create a client
const queryClient = new QueryClient();
// 3. extend the theme
const theme = extendTheme({ config });
root.render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="archive" element={<Archive />} />
              <Route path="login_success" element={<LoginSuccess />} />
              <Route path="about" element={<About />} />
              <Route path="queue/:queueName" element={<Queue />} />
              <Route path="faq" element={<FAQ />} />
              <Route
                path="assignmentadminlist"
                element={<AssignmentAdminList />}
              />
              <Route
                path="extensionadminlist/:assignmentId"
                element={<ExtensionAdminList />}
              />
              <Route path="serveradminlist" element={<ServerAdminList />} />
              <Route
                path="file/:assignmentId/:timestamp/:destServer/:fileName"
                element={<File />}
              />
              <Route
                path="filelist/:assignmentId/:timestamp/:destServer"
                element={<FileList />}
              />
              <Route
                path="test/:assignmentId/:timestamp/:destServer/:testId"
                element={<TestCase />}
              />
              <Route path="graphs/:assignmentId" element={<Graphs />} />
              <Route path="/studentadmin" element={<StudentAdmin />} />
            </Route>
            <Route path="/assignment" element={<AssignmentLayout />}>
              <Route path=":assignmentId" element={<Assignment />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
