import { useToast } from "@chakra-ui/react";
import { formatDate } from "@/lib/datetime";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { alertError, ResponseError } from "./error";
import { successful } from "./successMsg";
export type Extension = {
  uniqname: string;
  projectId: string;
  closingTime: string;
  comments: string;
  extensionGranter: string | null;
  createdAt: string | null;
};

export const queryExtension = async (assignmentId: string | undefined) => {
  const response = await fetch("/api/v1/extension/admin/" + assignmentId);
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  const extensions = await response.json();
  return Object.assign(new Array<Extension>(), extensions);
};

export const useQueryExtension = (assignmentId: string | undefined) =>
  useQuery({
    queryKey: ["user", "assignment", "extensionList", assignmentId],
    queryFn: async () => await queryExtension(assignmentId),
    refetchOnWindowFocus: false,
  });

export const useExtensionApply = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (extension: Extension) => {
      const response = await fetch("/api/v1/extension/admin/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(extension),
      });
      if (response.status !== 200) {
        const errorData = await response.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      const result = (await response.json()) as Extension;
      return result;
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "assignment", "extensionList"],
      }),
    onError: (err: Error) => alertError(toast, err),
    onSuccess: (data, extension: Extension, context) =>
      successful(toast, {
        title: "Extension added",
        msg:
          extension.projectId +
          " of " +
          extension.uniqname +
          " extended until " +
          formatDate(extension.closingTime),
      }),
  });
};

export const useExtensionLateDayApply = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: async (assignmentId: string) => {
      const response = await fetch(
        "/api/v1/extension/student/" + assignmentId,
        { method: "post" },
      );
      if (response.status !== 200) {
        const errorData = await response.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user", "assignmentDetail"],
      }),
    onError: (err: Error) => alertError(toast, err),
    onSuccess: () => successful(toast, { title: "Late day used", msg: null }),
  });
};
