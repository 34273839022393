import {
  useArchivedAssignment,
  useArchivedSemester,
  useArchivedSubmission,
  useArchivedUniqname,
} from "@/lib/archive";
import { Select } from "@chakra-ui/react";
import React from "react";
import {
  CircularPendingElement,
  LoadingFrame,
  QuestionMarkElement,
} from "./LoadingFrame";
import { StudentSelect } from "./StudentSelect";

export function SemesterSelector({
  semester,
  setSemester,
}: {
  semester: string | undefined;
  setSemester: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    setSemester(selectedValue);
  };
  const semesterView = (
    <LoadingFrame
      query={useArchivedSemester()}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(semesters) => (
        <>
          <Select
            placeholder="Choose Semester"
            value={semester}
            onChange={handleSelectChange}
          >
            {semesters.map((semester: string) => (
              <option value={semester} key={semester}>
                {semester}
              </option>
            ))}
          </Select>
        </>
      )}
    </LoadingFrame>
  );
  return semesterView;
}

export function AssignmentSelector({
  semester,
  assignment,
  setAssignment,
}: {
  semester: string;
  assignment: string | undefined;
  setAssignment: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    setAssignment(selectedValue);
  };
  const semesterView = (
    <LoadingFrame
      query={useArchivedAssignment(semester)}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(assignments) => (
        <>
          <Select
            placeholder="Choose Assignment"
            value={assignment}
            onChange={handleSelectChange}
          >
            {assignments.map((assignment: string) => (
              <option value={assignment} key={assignment}>
                {assignment}
              </option>
            ))}
          </Select>
        </>
      )}
    </LoadingFrame>
  );
  return semesterView;
}

export function UniqnameSelector({
  semester,
  assignment,
  uniqname,
  setUniqname,
}: {
  semester: string;
  assignment: string;
  uniqname: string | undefined;
  setUniqname: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const semesterView = (
    <LoadingFrame
      query={useArchivedUniqname(semester, assignment)}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(uniqnames) => (
        <>
          <StudentSelect
            uniqnames={uniqnames}
            uniqname={uniqname}
            setUniqname={setUniqname}
          />
        </>
      )}
    </LoadingFrame>
  );
  return semesterView;
}

export function SubmissionSelector({
  semester,
  assignment,
  uniqname,
  submission,
  setSubmission,
}: {
  semester: string;
  assignment: string;
  uniqname: string;
  submission: string | undefined;
  setSubmission: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    setSubmission(selectedValue);
  };
  const semesterView = (
    <LoadingFrame
      query={useArchivedSubmission(semester, assignment, uniqname)}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(submissions) => (
        <>
          <Select
            placeholder="Choose Submission"
            value={submission}
            onChange={handleSelectChange}
          >
            {submissions.map((submission: string) => (
              <option value={submission} key={submission}>
                {submission}
              </option>
            ))}
          </Select>
        </>
      )}
    </LoadingFrame>
  );
  return semesterView;
}
