import { useQuery } from "@tanstack/react-query";
import { Submission } from "./assignmentDetails";
import { ResponseError } from "./error";
import { pretendUrl, usePretend } from "./pretend";

export const useSubmissionQuery = (assignmentId: string, timestamp: string) => {
  const { pretend } = usePretend();
  return useQuery({
    queryKey: ["user", "submission", assignmentId, timestamp],
    queryFn: async () => {
      const url = "/api/v1/submission/" + assignmentId + "/" + timestamp;
      const res = await fetch(pretendUrl(url, pretend));
      if (res.status !== 200) {
        const errorData = await res.json();
        throw new ResponseError(errorData.description, errorData.status_code);
      }
      return (await res.json()) as Submission;
    },
    refetchOnWindowFocus: false,
  });
};
