import * as React from "react";
import SlimContainer from "@/components/SlimContainer";
import {
  Assignment,
  Assignment as AssignmentType,
  detailsPage,
  isProject,
  useAssignmentQuery,
} from "@/lib/assignment";
import {
  Card,
  CardHeader,
  CardBody,
  Divider,
  Button,
  Box,
  SimpleGrid,
  Text,
  CardFooter,
} from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { useUserQuery } from "@/lib/user";
import {
  CircularPendingElement,
  LoadingFrame,
  QuestionMarkElement,
} from "@/components/LoadingFrame";
import { LinkWithPretend } from "@/lib/pretend";
import { formatDate } from "@/lib/datetime";

function AssignmentDetailButton({
  assignment,
}: {
  assignment: AssignmentType;
}) {
  const url = detailsPage(assignment);
  return (
    <Button colorScheme="teal" as={LinkWithPretend} to={url}>
      Details
    </Button>
  );
}

function CardWithForm({
  assignment,
  loggedIn,
}: {
  assignment: AssignmentType;
  loggedIn: boolean;
}) {
  return (
    <Card>
      <CardHeader>
        <CardHeader>
          <Heading size="md">{assignment.projectName}</Heading>
        </CardHeader>
        <CardBody>
          <Box>
            <Text fontWeight={800}>Due: </Text>
            <Text>{formatDate(assignment.closingTime)}</Text>
          </Box>
        </CardBody>
        <CardFooter>
          {loggedIn ? (
            <AssignmentDetailButton assignment={assignment} />
          ) : (
            <></>
          )}
        </CardFooter>
      </CardHeader>
    </Card>
  );
}

function AssignmentGrid({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Box width="100%">
      <Text fontSize="xx-large" fontWeight={1000}>
        {title}
      </Text>
      <SimpleGrid
        columns={{ sm: 2, md: 3, lg: 4 }}
        spacing="10px"
        py={3}
        width="100%"
      >
        {children}
      </SimpleGrid>
    </Box>
  );
}
export default function Home() {
  const { data: user } = useUserQuery();
  const projectView = (
    <LoadingFrame
      query={useAssignmentQuery()}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(assignments) => (
        <AssignmentGrid title="Projects">
          {assignments.filter(isProject).map((assignment: Assignment) => (
            <CardWithForm
              assignment={assignment}
              loggedIn={user !== null}
              key={assignment.projectId}
            />
          ))}
        </AssignmentGrid>
      )}
    </LoadingFrame>
  );
  // const labView = loadingFrame(
  //   useAssignmentQuery(),
  //   <CircularPendingElement />,

  //   <QuestionMarkElement />,
  //   (err: Error) => alertError(toast, err),
  //   (assignments: Array<Assignment>) => (
  //     <AssignmentGrid title="Labs">
  //       {assignments
  //         .filter((assignment: Assignment) => !isProject(assignment))
  //         .map((assignment: Assignment) => (
  //           <CardWithForm
  //             assignment={assignment}
  //             loggedIn={user !== null}
  //             key={assignment.projectId}
  //           />
  //         ))}
  //     </AssignmentGrid>
  //   ),
  // );
  const labView = (
    <LoadingFrame
      query={useAssignmentQuery()}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(assignments) => (
        <AssignmentGrid title="Labs">
          {assignments
            .filter((assignment: Assignment) => !isProject(assignment))
            .map((assignment: Assignment) => (
              <CardWithForm
                assignment={assignment}
                loggedIn={user !== null}
                key={assignment.projectId}
              />
            ))}
        </AssignmentGrid>
      )}
    </LoadingFrame>
  );
  return (
    <main>
      <SlimContainer>
        {projectView}
        <Divider className="my-10" />
        {labView}
      </SlimContainer>
    </main>
  );
}
