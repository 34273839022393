import { create } from "zustand";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { pretendUrl, usePretend } from "./pretend";
import { useState } from "react";
import { Assignment } from "./assignment";
import { ResponseError } from "./error";
import { useToast } from "@chakra-ui/react";
import { alertError } from "@/lib/error";
import { successful } from "./successMsg";

export type Submission = {
  uniqname: string;
  projectId: string;
  timestamp: string;
  isGraded: number;
  isExtra: boolean;
  isViewable: boolean;
  buggySols: number;
  totalPoints: number;
  tokens: number;
  ipAddress: string;
  destServer: string;
  isFinalGradingRun: boolean;
};

export type RunResult = {
  uniqname: string;
  projectId: string;
  testId: string;
  timestamp: string;
  returnCode: number;
  runtime: number;
  memoryUsage: number;
  score: number;
};

export type Test = {
  projectId: string;
  testId: string;
  benchmark: number | null;
  memBenchmark: number | null;
};

export enum DuePhase {
  CAN_SUBMIT = "CAN_SUBMIT",
  PENDING_SUBMIT = "PENDING_SUBMIT",
  TOO_MUCH_SUBMIT = "TOO_MUCH_SUBMIT",
  PENALTY = "PENALTY",
  USE_LATE_DAY = "USE_LATE_DAY",
  CANNOT_SUBMIT = "CANNOT_SUBMIT",
}
export type DueState = {
  duePhase: DuePhase;
  extensionNeeded: number;
};

export type AssignmentDetail = {
  assignment: Assignment;
  lateDays: number;
  dueDateWithExtension: string;
  todayUsedSubmits: number;
  todayTotalSubmits: number;
  lateDaysLeft: number;
  submissions: Array<Submission>;
  runResults: Array<RunResult>;
  tests: Array<Test>;
  dueState: DueState;
};

export function duePhasePrompt(duePhase: DuePhase) {
  if (duePhase === DuePhase.CAN_SUBMIT) {
    return "You can submit now.";
  } else if (duePhase === DuePhase.PENDING_SUBMIT) {
    return "Wait for the previous submission to finish.";
  } else if (duePhase === DuePhase.TOO_MUCH_SUBMIT) {
    return "Daily submission limit reached.";
  } else if (duePhase === DuePhase.PENALTY) {
    return "Submit today with penalty.";
  } else if (duePhase === DuePhase.USE_LATE_DAY) {
    return "Use late day to submit.";
  } else if (duePhase === DuePhase.CANNOT_SUBMIT) {
    return "You cannot submit today.";
  }
}

function shouldRefresh(assignment: AssignmentDetail) {
  return (
    assignment.submissions.filter(
      (submission: Submission) => submission.isGraded !== 1,
    ).length > 0
  );
}

interface AssignmentDetailStore {
  assignmentName: string | null;
  assignmentId: string | null;
}

const queryAssignment = async (
  assignmentId: string,
  pretend: string | null,
) => {
  const url = pretendUrl("/api/v1/assignment/" + assignmentId, pretend);
  const response = await fetch(url);
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  const assignment = await response.json();
  const assignmentDetail = {
    ...assignment,
    tests: [...(assignment as AssignmentDetail).tests].sort((a, b) => {
      const aHidden = a.testId.startsWith("_");
      const bHidden = b.testId.startsWith("_");
      if (aHidden !== bHidden) {
        return aHidden ? 1 : -1;
      }
      return a.testId.localeCompare(b.testId);
    }),
  } as AssignmentDetail;
  return assignmentDetail;
};

export const useAssignmentDetailStore = create<AssignmentDetailStore>()(
  (set, get) => ({
    assignmentName: null,
    assignmentId: null,
  }),
);

export const useAssignmentDetailQuery = (assignmentId: string | null) => {
  const { pretend } = usePretend();
  const [refresh, setRefresh] = useState(false);
  return useQuery({
    queryFn: async () => {
      const assignment = await queryAssignment(
        assignmentId || "UNDEFINED",
        pretend,
      );
      setRefresh(shouldRefresh(assignment));
      return assignment;
    },
    queryKey: ["user", "assignmentDetail", assignmentId, pretend],
    refetchInterval: refresh ? 10000 : Infinity,
    refetchOnWindowFocus: false,
  });
};

export default async function sendSubmission(
  tarFile: File,
  assignmentId: string,
  pretend: string | null,
) {
  const url = pretendUrl("/api/v1/submission/" + assignmentId, pretend);
  var data = new FormData();
  data.append("tarFile", tarFile);
  const res = await fetch(url, {
    method: "POST",
    body: data,
  });
  if (res.status !== 200) {
    const errorData = await res.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  return await res.json();
}

export const useAssignmentSubmitQuery = (assignmentId: string | null) => {
  const toast = useToast();
  const { pretend } = usePretend();
  const queryClient = useQueryClient();
  return useMutation({
    onError: (error) => {
      alertError(toast, error);
    },
    mutationFn: async (tarFile: File) =>
      await sendSubmission(tarFile, assignmentId || "UNDEFINED", pretend),
    onSuccess: () =>
      successful(toast, {
        title: "Submission Successful",
        msg:
          "Submitted to " +
          assignmentId +
          ". Click on the timestamp for more details.",
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["user", "assignmentDetail"] });
      queryClient.invalidateQueries({ queryKey: ["user", "account"] });
    },
  });
};

type CellSetup = {
  background: string;
  text: string;
  passed: boolean;
};

function darkColor(color: string) {
  if (color === "green") {
    return "var(--chakra-colors-green-600)";
  } else if (color === "red") {
    return "var(--chakra-colors-red-700)";
  } else if (color === "blue") {
    return "var(--chakra-colors-blue-500)";
  } else if (color === "gray") {
    return "var(--chakra-colors-gray-500)";
  }
}

function lightColor(color: string) {
  if (color === "green") {
    return "var(--chakra-colors-green-300)";
  } else if (color === "red") {
    return "var(--chakra-colors-red-300)";
  } else if (color === "blue") {
    return "var(--chakra-colors-blue-300)";
  } else if (color === "gray") {
    return "var(--chakra-colors-gray-300)";
  }
}

function color(color: string, colorMode: string) {
  if (colorMode === "light") {
    return lightColor(color);
  } else {
    return darkColor(color);
  }
}

export function runResultCellSetup(
  runResult: RunResult,
  test: Test,
  colorMode: string,
) {
  if (runResult.returnCode === 0) {
    if (test.benchmark !== null && test.benchmark < runResult.runtime) {
      return {
        background: color("blue", colorMode),
        text: runResult.runtime.toFixed(3),
        passed: false,
      } as CellSetup;
    }
    if (
      test.memBenchmark !== null &&
      test.memBenchmark < runResult.memoryUsage
    ) {
      return {
        background: color("blue", colorMode),
        text: runResult.runtime.toFixed(3),
        passed: false,
      } as CellSetup;
    }
    return {
      background: color("green", colorMode),
      text: runResult.runtime.toFixed(3),
      passed: true,
    } as CellSetup;
  } else if (runResult.returnCode === 1) {
    return {
      background: color("red", colorMode),
      text: "SIG",
      passed: false,
    } as CellSetup;
  } else if (runResult.returnCode === 2) {
    return {
      background: color("blue", colorMode),
      text: "TLE",
      passed: false,
    } as CellSetup;
  } else if (runResult.returnCode === 6) {
    return {
      background: color("red", colorMode),
      text: "WA",
      passed: false,
    } as CellSetup;
  } else if (runResult.returnCode === -1 || runResult.returnCode === 9) {
    return {
      background: color("gray", colorMode),
      text: "INVAL",
      passed: false,
    } as CellSetup;
  } else {
    return {
      background: color("gray", colorMode),
      text: "ERR",
      passed: false,
    } as CellSetup;
  }
}
