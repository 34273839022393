import { CreateToastFnReturn } from "@chakra-ui/react";

export class ResponseError extends Error {
  description: string;
  status_code: number;
  timestamp: string;

  constructor(description: string, status_code: number) {
    super(description); // Initialize Error's message field
    console.log("ResponseError constructor");
    this.name = "Error " + status_code.toString(); // Set Error's name field
    this.message = description;
    this.description = description;
    this.status_code = status_code;
    this.timestamp = Date();

    // This is necessary for proper prototype chain when extending built-in classes
    Object.setPrototypeOf(this, ResponseError.prototype);
  }
}

export function alertMsgStyle(err: ResponseError) {
  return err.description + "\tStatus Code = " + err.status_code.toString();
}

export const alertError = (toast: CreateToastFnReturn, err: Error) => {
  const id = err.name + err.message + Date();

  if (!toast.isActive(id)) {
    toast({
      id: id,
      title: err.name,
      description: err.message,
      status: "error",
      duration: 8000,
      isClosable: true,
      position: "top-right",
    });
  }
};

// export const useAlertError = (error: Error | null) => {
//   const toast = useToast();
//   if (error) {
//     alertError(toast, error);
//   }
// };
