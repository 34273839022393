import { useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { ResponseError } from "./error";

const stringSortHelper = (s1: string, s2: string) => {
  return s1.localeCompare(s2);
};

const inverseStringSortHelper = (s1: string, s2: string) => {
  return stringSortHelper(s2, s1);
};

const sortSemesters = (semesters: string[]): string[] => {
  // Define priorities with strict typing
  const semesterPriority: Record<"w" | "s" | "f", number> = {
    w: 3, // Winter
    s: 2, // Spring
    f: 1, // Fall
  };

  return semesters.sort((a, b) => {
    const [semA, yearA] = [a[0], parseInt(a.slice(1))];
    const [semB, yearB] = [b[0], parseInt(b.slice(1))];

    // Compare by year first
    if (yearA !== yearB) {
      return yearB - yearA; // Sort descending by year
    }

    // Compare by semester priority
    return (
      semesterPriority[semB as "w" | "s" | "f"] -
      semesterPriority[semA as "w" | "s" | "f"]
    );
  });
};

async function querySemester() {
  const res = await fetch("/api/v1/archive/search/");
  if (res.status !== 200) {
    throw new Error("Archive Fetch Failed.");
  }
  return sortSemesters(Object.assign(new Array<string>(), await res.json()));
}

export const useArchivedSemester = () => {
  return useQuery({
    queryFn: async () => await querySemester(),
    queryKey: ["user", "archivedSemester"],
    refetchOnWindowFocus: false,
  });
};

async function queryAssignment(semester: string) {
  const res = await fetch("/api/v1/archive/search/" + semester + "/");
  if (res.status !== 200) {
    throw new Error("Archive Fetch Failed.");
  }
  return Object.assign(new Array<string>(), await res.json()).sort(
    stringSortHelper,
  );
}

export const useArchivedAssignment = (semester: string) => {
  return useQuery({
    queryFn: async () => await queryAssignment(semester),
    queryKey: ["user", "archivedSemester", semester, "archivedAssignment"],
    refetchOnWindowFocus: false,
  });
};

async function queryUniqname(semester: string, assignment: string) {
  const res = await fetch(
    "/api/v1/archive/search/" + semester + "/" + assignment + "/",
  );
  if (res.status !== 200) {
    throw new Error("Archive Fetch Failed.");
  }
  const list = Object.assign(new Array<string>(), await res.json()).sort(
    stringSortHelper,
  );
  const sorted = list.sort(stringSortHelper);
  return sorted;
}

export const useArchivedUniqname = (semester: string, assignment: string) => {
  return useQuery({
    queryFn: async () => await queryUniqname(semester, assignment),
    queryKey: [
      "user",
      "archivedSemester",
      semester,
      "archivedAssignment",
      assignment,
      "archivedUniqname",
    ],
    refetchOnWindowFocus: false,
  });
};

async function querySubmission(
  semester: string,
  assignment: string,
  uniqname: string,
) {
  const res = await fetch(
    "/api/v1/archive/search/" +
      semester +
      "/" +
      assignment +
      "/" +
      uniqname +
      "/",
  );
  if (res.status !== 200) {
    throw new Error("Archive Fetch Failed.");
  }
  return Object.assign(new Array<string>(), await res.json()).sort(
    inverseStringSortHelper,
  );
}

export const useArchivedSubmission = (
  semester: string,
  assignment: string,
  uniqname: string,
) => {
  return useQuery({
    queryFn: async () => await querySubmission(semester, assignment, uniqname),
    queryKey: [
      "user",
      "archivedSemester",
      semester,
      "archivedAssignment",
      assignment,
      "archivedUniqname",
      uniqname,
      "archivedSubmission",
    ],
    refetchOnWindowFocus: false,
  });
};

export async function downloadFile(
  semester: string,
  assignmentId: string,
  uniqname: string,
  submission: string,
) {
  if (!assignmentId || !semester) {
    return;
  }
  const url =
    "/api/v1/archive/download/" +
    semester +
    "/" +
    assignmentId +
    "/" +
    uniqname +
    "/" +
    submission;
  const response = await fetch(url);
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new ResponseError(errorData.description, errorData.status_code);
  }
  const data = await response.blob();
  return saveAs(data, uniqname + "-" + semester + "-" + assignmentId + ".tgz");
}
