import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  SimpleGrid,
  Center,
  Link,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import SlimContainer from "@/components/SlimContainer";
import { useFileListQuery } from "@/lib/file";
import {
  CircularPendingElement,
  LoadingFrame,
  QuestionMarkElement,
} from "@/components/LoadingFrame";
import {
  AssignmentDetail,
  RunResult,
  runResultCellSetup,
  Submission,
  Test,
  useAssignmentDetailQuery,
} from "@/lib/assignmentDetails";
import { LinkWithPretend } from "@/lib/pretend";

type FilterSetup = {
  name: string;
  regex: string;
};

function TestDetails() {
  const { colorMode } = useColorMode();
  const { assignmentId, timestamp } = useParams();
  const assignmentDetailQuery = useAssignmentDetailQuery(
    assignmentId || "UNDEFINED",
  );
  const testView = (
    <LoadingFrame
      query={assignmentDetailQuery}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(assignment: AssignmentDetail) => (
        <SimpleGrid columns={[3, 5, 10]} width="100%" spacing={1}>
          {assignment.tests.map((test: Test) => {
            const submission = assignment.submissions.find(
              (sub: Submission) => sub.timestamp === timestamp,
            ) as Submission;
            const destServer = submission.destServer;
            const runResult = assignment.runResults.find(
              (result: RunResult) =>
                result.testId === test.testId &&
                result.timestamp === timestamp &&
                result.projectId === assignmentId,
            );
            if (runResult === undefined) {
              return <Center>N/A</Center>;
            }
            const cellSetup = runResultCellSetup(runResult, test, colorMode);
            return (
              <Link
                as={LinkWithPretend}
                to={
                  "/test/" +
                  assignmentId +
                  "/" +
                  timestamp +
                  "/" +
                  destServer +
                  "/" +
                  test.testId
                }
              >
                <Box background={cellSetup.background} paddingY={2}>
                  <Center>{test.testId}</Center>
                </Box>
              </Link>
            );
          })}
        </SimpleGrid>
      )}
    </LoadingFrame>
  );
  return testView;
}

function filterByRegex(files: Array<string>, filterSetup: FilterSetup) {
  const re = RegExp(filterSetup.regex);
  return files.filter((name: string) => {
    return re.test(name);
  });
}

function accordionItemFileList(filterSetup: FilterSetup, files: Array<string>) {
  const filteredFiles = filterByRegex(files, filterSetup);
  return (
    <AccordionItem key={filterSetup.name}>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <Text fontSize="x-large">{filterSetup.name}</Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        {filteredFiles.length === 0 ? (
          <Box w="100%">
            <Center>
              <Text color="gray" fontSize="xx-large">
                Nothing
              </Text>
            </Center>
          </Box>
        ) : (
          <SimpleGrid columns={[1, 3, 7]} spacing={1}>
            {filteredFiles.map((fileName: string) => (
              <FileButton
                fileName={fileName}
                key={filterSetup.name + fileName}
              />
            ))}
          </SimpleGrid>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

function FileButton({ fileName }: { fileName: string }) {
  const { assignmentId, timestamp, destServer } = useParams();
  const url =
    "/file/" +
    assignmentId +
    "/" +
    timestamp +
    "/" +
    destServer +
    "/" +
    fileName;
  return (
    <Box>
      <Link as={LinkWithPretend} to={url}>
        {fileName}
      </Link>
    </Box>
  );
}

const regexFilterSetups: Array<FilterSetup> = [
  {
    name: "Compilation",
    regex:
      "(^cppcheck.txt|^make(_clean)?\\.(stderr|stdout)$|^make_safe\\.(stderr|stdout)|^identifier_check\\.(stderr|stdout)|make.pq)",
  },
  { name: "Autograder Output", regex: "^run_out" },
  { name: "Sources", regex: "(^Makefile$|.h$|.cpp$|.hpp$)" },
  { name: "Valgrind", regex: "^valgrind" },
  { name: "Student Testcases", regex: "test-.+\\.txt$" },
  { name: "Raw Output", regex: "\\.(stdout|stderr)$" },
  { name: "All", regex: ".*" },
];

function FileListView({ files }: { files: Array<string> }) {
  const expandAll = regexFilterSetups.map((_, idx) => idx);
  return (
    <Accordion defaultIndex={expandAll} allowMultiple width="100%">
      {regexFilterSetups.map((setup) => accordionItemFileList(setup, files))}
    </Accordion>
  );
}

export default function FileList() {
  const { assignmentId, timestamp, destServer } = useParams();
  const fileListQuery = useFileListQuery(
    assignmentId || "UNDEFINED",
    timestamp || "UNDEFINED",
    destServer || "UNDEFINED",
  );
  const fileListView = (
    <LoadingFrame
      query={fileListQuery}
      PendingElement={<CircularPendingElement />}
      ErrorElement={<QuestionMarkElement />}
    >
      {(files) => <FileListView files={files} />}
    </LoadingFrame>
  );
  return (
    <SlimContainer>
      <VStack>
        <TestDetails />
        {fileListView}
      </VStack>
    </SlimContainer>
  );
}
